/*
Title: AboutPage Component

Description:
This component renders the About page of the application. It includes a heading and applies specific styles from the associated SCSS file. The document title is dynamically set using the `useDocumentTitle` custom hook.

Props:
- None

State:
- None

Functions:
- AboutPage: The main function component that renders the About page content and sets the document title.

Usage:
1. Import the component: `import AboutPage from './AboutPage/About.page';`
2. Use the component in your routing or as needed:
   ```jsx
   <Route path="/about" component={AboutPage} />

Author: thesyscoder
*/

import React from "react";
import "./About.page.scss";
import { useDocumentTitle } from "../../hooks";

const AboutPage = () => {
  useDocumentTitle("Shamrock | About", false);
  return (
    <div className="aboutpage-container">
      <h1>About Page</h1>
    </div>
  );
};
export default React.memo(AboutPage);
