/*
Title: index

Description:
This file is the entry point for the Shamrock application. It sets up the React root and renders the `App` component into the DOM. It also wraps the `App` component with `React.StrictMode` for highlighting potential problems in the application.

Usage:
1. Ensure that this file is set as the entry point in your build configuration.
2. The `App` component will be rendered inside the HTML element with the ID `root`.

Example:
- No direct usage in application code, but this file initializes the app.

Author: thesyscoder
*/

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";

// Create a root for the React application
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the App component into the root element
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
