/*
Title: ContactPage Component

Description:
This component renders the Contact page of the application. It displays a welcoming message and applies styles from the associated SCSS file. The document title is dynamically set using the `useDocumentTitle` custom hook.

Props:
- None

State:
- None

Functions:
- ContactPage: The main function component that renders the Contact page content and sets the document title.

Usage:
1. Import the component: `import ContactPage from './ContactPage/Contact.page';`
2. Use the component in your routing or as needed:
   ```jsx
   <Route path="/contact" component={ContactPage} />

Author: thesyscoder
*/

import React from "react";
import "./Contact.page.scss";
import { useDocumentTitle } from "../../hooks";

const ContactPage = () => {
  useDocumentTitle("Shamrock | Contact", false);
  return (
    <div className="contactpage-container">
      <h1>Welcome to contact page</h1>
    </div>
  );
};
export default React.memo(ContactPage);
