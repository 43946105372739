/*
Title: ErrorPage Component

Description:
This component renders the Error page of the application. It displays a simple error message and applies styles from the associated SCSS file. The document title is set using the `useDocumentTitle` custom hook to indicate an error state.

Props:
- None

State:
- None

Functions:
- ErrorPage: The main function component that renders the Error page content and sets the document title.

Usage:
1. Import the component: `import ErrorPage from './Error.page/Error.page';`
2. Use the component in your routing or as needed:
   ```jsx
   <Route path="*" component={ErrorPage} />

Author: thesyscoder
*/

import React from "react";
import "./Error.page.scss";
import { useDocumentTitle } from "../../hooks";

const ErrorPage = () => {
  useDocumentTitle("Shamrock | Error");
  return (
    <div className="errorpage-container">
      <h1>Error Page</h1>
    </div>
  );
};
export default React.memo(ErrorPage);
