/*
Title: Navbar

Description:
This component renders a navigation bar with a brand link, menu items, and buttons for login and signup. 
It dynamically changes the navbar's styling based on the scroll position. The component is optimized with `React.memo` to avoid unnecessary re-renders.

Usage:
1. Import the Navbar component into your React application.
2. Ensure that routes for the navigation links and button actions are properly configured in your `react-router-dom` setup.
3. Use the component as part of your application's layout.

Example:
<Navbar />

Author: thesyscoder
*/

import React from "react";
import "./Navbar.style.scss";
import { NavLink } from "react-router-dom";
import { Button } from "../../atoms";

const Navbar = () => {
  return (
    <div className="navbar">
      {/* Brand */}
      <div className="brand-link">
        <NavLink to="/" className="brand-navlink">
          <h2 className="span">Shamrock</h2>
        </NavLink>
      </div>
      {/* Menu list */}
      <div className="menu-links">
        <ul className="menu-list">
          <li className="menu-item">
            <NavLink to="/" className="navlink-menu">
              Home
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/about" className="navlink-menu">
              About Us
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/export" className="navlink-menu">
              Export
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/import" className="navlink-menu">
              Import
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/services" className="navlink-menu">
              Services
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="/contact" className="navlink-menu">
              Contact US
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Login & Sign Up */}
      <div className="login-signup-container">
        <ul className="nav-button-list">
          <li className="button">
            <NavLink to="/login">
              <Button className="secondary">Login</Button>
            </NavLink>
          </li>
          <li className="button">
            <NavLink to="/signup">
              <Button className="primary">Sign Up</Button>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default React.memo(Navbar);
