/*
Title: LoginPage

Description:
This component represents the login page of the Shamrock application. It provides options to log in using social media accounts (Google and Facebook) or with email and password. It includes input fields for email and password, and renders buttons for social media login.

Usage:
1. Import the LoginPage component into your React application.
2. Use the component to display the login page where users can log in via social media or email.

Example:
<LoginPage />

Author: thesyscoder
*/

import React from "react";
import "./Login.style.scss";
/**
 * LoginPage component for rendering the login page of the Shamrock application.
 *
 * @returns {JSX.Element} The rendered login page element.
 */
const LoginPage = () => {
  return <div className="login-container">This is login page</div>;
};

export default React.memo(LoginPage);
