/*
Title: useDocumentTitle

Description:
This custom hook allows you to set the document title for a React component. It updates the document's title when the component is mounted and can optionally revert to the previous title when the component is unmounted. 

Parameters:
- title (string): The title to set for the document.
- prevailOnUnmount (boolean): If true, the title set by the component will persist even after the component is unmounted. Default is false.

Functions:
- useDocumentTitle: Manages the document title during the lifecycle of a component.

Usage:
1. Import the hook: `import useDocumentTitle from './useDocumentTitle';`
2. Use it in a component:
   ```jsx
   function MyComponent() {
     useDocumentTitle("My Page Title");
     return <div>Content</div>;
   }
Author: thesyscoder
*/

import { useRef, useEffect } from "react";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    // Store the current value of defaultTitle.current in a local variable
    const prevDefaultTitle = defaultTitle.current;

    return () => {
      if (!prevailOnUnmount) {
        document.title = prevDefaultTitle;
      }
    };
  }, [prevailOnUnmount]);

  // If you want to set a default title when the component mounts
  useEffect(() => {
    return () => {
      document.title = "thesyscoder | Default Title";
    };
  }, []);

  // Optionally, you can return the current document title if needed
  // return document.title;
}

export default useDocumentTitle;
