/*
Title: ImportPage

Description:
This component represents the import page of the Shamrock application. It renders a simple page with a header indicating it's the import page.

Usage:
1. Import the ImportPage component into your React application.
2. Use the component to display the import page content.

Example:
<ImportPage />

Author: thesyscoder
*/

import React from "react";
import "./Import.style.scss";

/**
 * ImportPage component for rendering the import page of the Shamrock application.
 *
 * @returns {JSX.Element} The rendered import page element.
 */
const ImportPage = () => {
  return (
    <div className="import-page-container">
      <h1>This is the import page</h1>
    </div>
  );
};

export default React.memo(ImportPage);
