/*
Title: App

Description:
This component sets up the main application layout and routing for the Shamrock application. It uses `react-router-dom` to define routes and render different pages. 
The `AppLayout` component conditionally displays the `Navbar` based on the current route, and the `RouterProvider` manages routing across the application.

Usage:
1. Import the App component into your React application.
2. Use the component to set up routing and render the main application layout.

Example:
<App />

Author: thesyscoder
*/

import React from "react";
import "./App.scss";
import { Navbar } from "./components/molecules";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import {
  HomePage,
  AboutPage,
  ContactPage,
  ErrorPage,
  ImportPage,
  ExportPage,
  LoginPage,
  SignUpPage,
  ServicesPage,
} from "./pages";

// Create AppLayout component to conditionally render the Navbar
const AppLayout = () => {
  const location = useLocation();
  const isLoginPageOrSignUpPage =
    location.pathname === "/login" || location.pathname === "/signup";

  return (
    <>
      {!isLoginPageOrSignUpPage && <Navbar />}
      <div className="content-container">
        <Outlet />
      </div>
    </>
  );
};

// Main App component to set up routing
const App = () => {
  const AppRouter = createBrowserRouter([
    {
      element: <AppLayout />,
      errorElement: <ErrorPage />,
      children: [
        { path: "/", element: <HomePage /> },
        { path: "/export", element: <ExportPage /> },
        { path: "/import", element: <ImportPage /> },
        { path: "/about", element: <AboutPage /> },
        { path: "/contact", element: <ContactPage /> },
        { path: "/services", element: <ServicesPage /> },
        { path: "/login", element: <LoginPage /> },
        { path: "/signup", element: <SignUpPage /> },
      ],
    },
  ]);

  return <RouterProvider router={AppRouter} />;
};

export default React.memo(App);
