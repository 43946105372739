/*
Title: HomePage

Description:
This component represents the home page of the Shamrock application. It sets the document title to "Shamrock | Home" using a custom hook and renders the Hero component.

Usage:
1. Import the HomePage component into your React application.
2. Use the component to display the home page content with the Hero section.

Example:
<HomePage />

Author: thesyscoder
*/

import React from "react";
import "./Home.style.scss";
import { useDocumentTitle } from "../../hooks";
import { Chat, MailOption, Location } from "grommet-icons";

/**
 * HomePage component for rendering the home page of the Shamrock application.
 *
 * @returns {JSX.Element} The rendered home page element.
 */
const HomePage = () => {
  useDocumentTitle("Shamrock | Home", false);

  return (
    <div className="min-h-screen flex flex-col justify-between">
      {/* Hero Section */}
      <section
        className="bg-cover bg-center text-white py-32"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url('https://img.freepik.com/free-photo/aerial-view-cargo-ship-cargo-container-harbor_335224-1380.jpg?w=1380&t=st=1725804941~exp=1725805541~hmac=c779509463fad06e7855469e6e920aa1c75ba4daf2ea2ad75d36418fb2779500')`,
        }}
      >
        <div className="container mx-auto text-center">
          <p className="text-3xl">
            Welcome to Shamrock Eximworld Pvt Ltd – Your Trusted Partner in
            Global Export & Import
          </p>
          <p className="text-3xl mt-2">
            "Delivering Premium Dehydrated Goods with Unmatched Quality, Global
            Reach, and Personalized Service."
          </p>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-16 bg-gray-100">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">About Us</h2>
          <p className="text-lg text-gray-600 mb-4">
            At Shamrock Eximworld Pvt Ltd, we specialize in providing premium
            dehydrated fruits, vegetables, herbs, and egg powder to markets
            around the world.
          </p>
          <p className="text-lg text-gray-600">
            <strong>Our Mission:</strong> To deliver high-quality products that
            retain their natural flavors, colors, and nutritional value,
            ensuring our customers receive the best possible ingredients for
            their needs.
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            What We Offer
          </h2>
          <p className="text-lg text-gray-600 mb-8">
            We take pride in our meticulously processed products, undergoing
            rigorous quality control to meet the highest international
            standards.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white shadow-md p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Premium Quality
              </h3>
              <p className="text-gray-600">
                We ensure that every product is processed under strict quality
                control measures.
              </p>
            </div>
            <div className="bg-white shadow-md p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Global Reach
              </h3>
              <p className="text-gray-600">
                With an extensive logistics network, we guarantee timely and
                efficient delivery.
              </p>
            </div>
            <div className="bg-white shadow-md p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Personalized Service
              </h3>
              <p className="text-gray-600">
                We build lasting relationships by offering customized solutions.
              </p>
            </div>
            <div className="bg-white shadow-md p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Compliance & Reliability
              </h3>
              <p className="text-gray-600">
                We adhere to all international export regulations, providing a
                seamless experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-16 bg-gray-100">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Contact Us</h2>
          <p className="text-lg text-gray-600 mb-4">
            <Chat></Chat> +91 7840979408
          </p>
          <p className="text-lg text-gray-600 mb-8">
            <MailOption />
            shamrockeximworld24@gmail.com
          </p>
          <p className="text-lg text-gray-600 mb-4">
            <Location></Location> Ghargoan, Ahmednagar, Maharashtra - 413728
          </p>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2023 Shamrock Eximworld Pvt Ltd. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default React.memo(HomePage);
