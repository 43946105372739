/*
Title: ExportPage

Description:
This component represents a page dedicated to the export functionality. It displays a heading indicating that this is the export page.

Usage:
1. Import the ExportPage component into your React application.
2. Render the component where needed within your application's routing or layout.

Example:
<ExportPage />

Author: thesyscoder
*/

import React from "react";
import "./Export.styles.scss";

/**
 * ExportPage component for rendering the export page layout.
 *
 * @returns {JSX.Element} The rendered export page element.
 */
const ExportPage = () => {
  return (
    <div className="export-page-container">
      <h1>This is the export page</h1>
    </div>
  );
};

export default React.memo(ExportPage);
